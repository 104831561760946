<template>
  <div>
    <b-row class="mb-2">
      <b-col xl="3">
    <b-form-group label="Выберите даты">
      <date-range-picker
          id="datepicker"
          :singleDatePicker="isDatePickerSingle"
          ref="picker"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          :maxDate="new Date()"
          autoApply
          opens="right"
          lang="ru"
          v-model="dateRange"
          @update="updateRangePicker"
          linkedCalendars
      />
    </b-form-group>
        <b-checkbox v-model="isPrevPeriod" @change="handlePrevPeriod">Предыдущий период</b-checkbox>
      </b-col>

      <b-col xl="2" class="d-flex dashboard__group-by">
        <b-form-group label="Группировка">
          <b-form-checkbox-group
              class="group-by d-flex flex-wrap"
              id="group"
              @change="changeGroup"
              v-model="group"
              name="group"
              stacked
          >
            <b-form-checkbox v-for="checkbox in groupOptions" :value="checkbox.value" class="mr-1">{{checkbox.text}}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col xl="5" class="d-flex" v-if="!isCreatingPreset">
    <DropDownFilter
        v-if="!renamePreset"
        placeholder="Выберите пресет"
        is-removable
        close-on-select
        :facets="dashboardPresetsList"
        label="Пресеты"
        :on-change="pickPreset"
        :multiple="false"
        no-options-text="У вас еще нет пресетов"
        @remove="removePresetHandler"
        :state="currentPresetState"
        track-by="id"  name="presets"/>
        <form-input-wrap v-show="renamePreset" id="rename" vid="rename" label="Переименовать пресет" rules="required">
          <b-form-input id="rename" v-model="renamePreset"></b-form-input>
        </form-input-wrap>

        <b-button v-if="currentPresetState.length && !renamePreset" class="dashboard__create-btn" @click="activateRenamePreset">Переименовать</b-button>
        <div v-else-if="currentPresetState && renamePreset" class="d-flex publisher-statistic__preset-btn-gap">
          <b-button variant="primary" class="dashboard__create-btn" @click="handleRenamePreset">Сохранить</b-button>
          <b-button class="dashboard__create-btn" @click="renamePreset = null">Отмена</b-button>
        </div>
        <BButton v-show="!renamePreset" class="dashboard__create-btn" @click="isCreatingPreset=true" variant="primary">Создать пресет</BButton>
      </b-col>

      <b-col v-else xl="7" class="">
        <ValidationObserver ref="form" v-slot="{ invalid }" class="d-flex align-items-center">
          <form-input label="Создание нового пресета" class="dashboard__preset-name" id="preset" vid="preset" v-model="newPresetName" placeholder="Новый пресет" rules="required"></form-input>

          <form-input-wrap
              id="reports"
              class="ml-1 mr-1 col-sm-4 dashboard__reports"
              vid="reports"
              label="Отчеты"
              label-hidden
              rules="required"
          >
            <DropDownFilter
                class=""
                placeholder="Выберите отчеты"
                :facets="presetsList"
                :on-change="pickReports"
                :multiple="true"
                v-model="reportsToPreset"
                :state="reportsToPreset"
                track-by="id"  name="reports"/>
          </form-input-wrap>
        <BButton class="mt-1 mr-1" variant="primary" @click="handleSavePreset">Сохранить</BButton>
        <BButton class="mt-1" @click="isCreatingPreset = false">Отменить</BButton>
        </ValidationObserver>
      </b-col>
  </b-row>
    <DashboardReport v-if="currentPreset && currentPreset.reports && currentPreset.reports.length" v-for="report in currentPreset.reports" :key="report" :report="report" :group="group[0]"
                     :date-range="dateRange"
                     :visibility-state="visibilityState[currentPreset.id] || {}"
                     @change="changeVis"/>
  </div>
</template>

<script>

import * as validations from '@validations'
import {setWith} from "lodash"
import DashboardReport from "@/components/DashboardReport.vue"
import {BButton, BFormGroup, BFormInput} from "bootstrap-vue"
import DateRangePicker from '@lexankh/vue2-daterange-picker'
import {reactive, ref, watch} from "@vue/composition-api"
import DropDownFilter from "@/components/DropDownFilter.vue"
import statisticLists from "@/use/statisticLists"
import FormInput from "@/components/FormInput.vue"
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  editDashboardPreset,
  fetchDashboardPresets,
  removeDashboardPreset,
  storeDashboardPreset
} from "@/api/lists"
import FormInputWrap from "@/components/FormInputWrap.vue"
import date from "vue-good-table/src/components/types/date"

export default {
  name: 'Dashboard',
  components: {
    BButton,
    BFormInput,
    FormInputWrap,
    FormInput,
    DropDownFilter,
    BFormGroup,
    ValidationProvider,
    DashboardReport,
    ValidationObserver,
    DateRangePicker
  },

  setup() {
    const {presetsList,
      handleLoadLists} = statisticLists();
    handleLoadLists(['presets']);

    const reportsToPreset = ref([]);
    const form = ref(null);
    const newPresetName = ref('');
    const renamePreset = ref(null);

    const isCreatingPreset = ref(false);
    const initStartDate = new Date();
    const dateRange = ref({
      startDate: new Date(initStartDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    })
    const isDatePickerSingle = ref(false);
    const isPrevPeriod = ref(false);

    const dashboardPresetsList = ref([])
    const currentPreset = ref({})
    const currentPresetState = ref([])
    let visibilityState = reactive({});


    // get visibility state
    if(localStorage.getItem('dashboard-settings')) visibilityState = JSON.parse(localStorage.getItem('dashboard-settings'));

    const handleFetchDashboardPresets = (loadLast = true) => {
      fetchDashboardPresets()
          .then((res) => {
            dashboardPresetsList.value = res.data.data;
            if(loadLast) handleFetchLastPreset();
          });
    }

    handleFetchDashboardPresets();

    const handleFetchLastPreset = () => {
      const preset = localStorage.getItem('dashboard-preset');
      if (!preset) return;
        currentPresetState.value = [+preset];
        currentPreset.value = dashboardPresetsList.value.filter(i => preset == i.id)[0] || {};
    }

    const group = ref(['date']);
    const groupOptions = [
      {
        text: 'Дата',
        value: 'date'
      },
      {
        text: 'По часам',
        value: 'hour',
      }]

    const changeGroup = (val) => {
      group.value = [val[1]]

      if(val[1] === 'hour') {
        isDatePickerSingle.value = true;
        dateRange.value.endDate = new Date();
        dateRange.value.startDate = new Date();
      } else {
        isDatePickerSingle.value = false;
        dateRange.value.startDate = new Date(initStartDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        dateRange.value.endDate = new Date();
      }
    }

    const updateRangePicker = () => {
      isPrevPeriod.value = false
    }
    const removePresetHandler = (id) => {
      removeDashboardPreset(id)
          .then(() => {
            handleFetchDashboardPresets();
          })
    }

    const pickPreset = (val) => {
      currentPresetState.value = val;
      currentPreset.value = dashboardPresetsList.value.filter(i => val[0] === i.id)[0];
      localStorage.setItem('dashboard-preset', currentPresetState.value[0]);
    }

    const pickReports = (val) => {
      reportsToPreset.value = val;
    }

    const handlePrevPeriod = (val) => {
      if(val) {
        const startDate = new Date(dateRange.value.startDate);
        startDate.setMonth(startDate.getMonth() - 1);

        const endDate = new Date(dateRange.value.endDate);
        endDate.setMonth(endDate.getMonth() - 1);

        dateRange.value.startDate = startDate;
        dateRange.value.endDate = endDate;
      } else {
        const startDate = new Date(dateRange.value.startDate);
        startDate.setMonth(startDate.getMonth() + 1);

        const endDate = new Date(dateRange.value.endDate);
        endDate.setMonth(endDate.getMonth() + 1);

        dateRange.value.startDate = startDate;
        dateRange.value.endDate = endDate;
      }
    }
    const handleSavePreset = async () => {
      const isValid = await form.value.validate();

      if(isValid) {
        storeDashboardPreset({
          name: newPresetName.value,
          reports: reportsToPreset.value.join(',')
        })
            .then((res) => {
              isCreatingPreset.value = false;
              newPresetName.value = '';
              reportsToPreset.value = [];
              handleFetchDashboardPresets();
            })
            .catch((err) => {
              console.log(err);
            })
      }
    }

    const storeVisibilityState = (preset, report, type, state) => {
      setWith(visibilityState, `${preset}.${report}.${type}`, state, Object);
      localStorage.setItem('dashboard-settings', JSON.stringify(visibilityState));
    }

    const changeVis = (data) => {
      storeVisibilityState(currentPreset.value.id, ...data);
    }

    const activateRenamePreset = () => {
      renamePreset.value = currentPreset.value.name;
    }

    const handleRenamePreset = () => {
      editDashboardPreset(currentPreset.value.id, {
        name: renamePreset.value
      })
          .then((res) => {
            fetchDashboardPresets(false);
            currentPreset.value.name = renamePreset.value;
            renamePreset.value = null;
          })
          .catch((err) => {
            console.log(err);
          })
    }

    return {
      activateRenamePreset,
      handleRenamePreset,
      renamePreset,
      updateRangePicker,
      isPrevPeriod,
      dateRange,
      presetsList,
      currentPreset,
      handlePrevPeriod,
      currentPresetState,
      isCreatingPreset,
      dashboardPresetsList,
      reportsToPreset,
      isDatePickerSingle,
      newPresetName,
      pickPreset,
      form,
      pickReports,
      handleSavePreset,
      visibilityState,
      groupOptions,
      group,
      changeGroup,
      removePresetHandler,
      changeVis,
      ...validations
    }
  }
}
</script>
<style src="@lexankh/vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.reportrange-text.form-control {
  padding: 8px 40px 0 8px;
  border: 1px solid #e8e8e8;
}

.r-filter-cell__label {
  padding-bottom: calc(0.438rem + 1px);
}

.dashboard {
  &__create-btn {
    align-self: center;
    margin-top: -1rem;
    margin-left: 1rem;

    @media screen and (max-width: 1199px) {
      margin-top: 1.636rem;
      margin-left: 1rem;
    }
  }

  &__preset-name label {
    padding-bottom: calc(0.438rem + 1px);
    margin-bottom: 0;
  }

  &__reports {
    padding-top: 2rem;
  }

  &__group-by {

    & legend {
      padding-bottom: 1.5rem;
    }
  }
}

.form-group > div {
  position: relative;
}

.form-group small {
  position: absolute;
}
</style>
